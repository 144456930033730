<template>
    <el-card class="subElderlyReport">
        <div class="searchBox">
            <el-form ref="form" :inline="true" :model="searchParams" label-width="70px">
                <el-form-item label="起止月份">
                    <el-date-picker
                        style="margin-right: 10px"
                        v-model="month"
                        type="monthrange"
                        range-separator="至"
                        start-placeholder="开始月份"
                        end-placeholder="结束月份"
                        :default-value="month"
                        value-format="yyyy-MM"
                        @change="onChangeDate"
                        :picker-options="monthpickoption"
                        :clearable="false"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="行政区划">
                    <el-cascader style="width: 270px" :props="areaProps" placeholder="请选择所属区划" v-model="org" @change="change" clearable></el-cascader>
                </el-form-item>
                <el-form-item label="身份类别">
                    <el-select class="w200px" clearable v-model="searchParams.subsidyType" placeholder="请选择身份类别">
                        <el-option v-for="item in IDCATEGORY" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索">
                    <el-input clearable v-model="searchParams.keyword" placeholder="请输入关键字" style="width: 200px" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" class="search" @click="doSearch">搜索</el-button>
                    <el-button type="primary" plain @click="doReset">重置</el-button>
                </el-form-item>
                <el-button type="primary" class="add" @click="doExport">导出</el-button>
            </el-form>
        </div>
        <div class="content">
            <el-table v-loading="loading" :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle">
                <el-table-column type="index" label="序号" />
                <el-table-column prop="userName" label="姓名" />
                <el-table-column prop="cardId" label="身份证号" width="180px" />
                <el-table-column prop="userPhone" label="联系电话" />
                <el-table-column prop="age" label="年龄" width="80px" />
                <el-table-column prop="sex" label="性别" width="80px">
                    <template slot-scope="scope">
                        <span>{{ getContent(SEXLIST, scope.row.sex, 'label', 'text') }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="streetName" label="所属区域">
                    <template slot-scope="scope">
                        <span>{{ `${scope.row.streetName || ''}${scope.row.communityName || ''}` }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="subsidyType" label="身份类别">
                    <template slot-scope="scope">
                        <span>{{ getContent(IDCATEGORY, scope.row.subsidyType, 'label', 'text') }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="bankDeposit" label="开户行" />
                <el-table-column prop="bankCard" label="银行卡号" />
                <el-table-column prop="bankUserName" label="持卡人姓名" />
                <el-table-column prop="subsidyStandard" label="月标准（元）"> </el-table-column>
                <el-table-column prop="subsidyCount" label="补贴月数"> </el-table-column>
                <el-table-column prop="totalSubsidy" label="补贴金额（元）"> </el-table-column>
                <el-table-column prop="remark" label="说明"> </el-table-column>
            </el-table>
            <el-pagination
                style="margin: 20px 0"
                background
                layout="total, sizes, prev, pager, next"
                :total="total"
                :page-size.sync="queryParams.pageSize"
                :current-page.sync="queryParams.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                @size-change="doSearch"
                @current-change="getList"
            />
        </div>
    </el-card>
</template>

<script>
import { administrativeDivision } from '@/api/institutionalManage'
import { IDCATEGORY, SEXLIST } from '@/data/dataDictionary'
import { reportList, reportListExport } from '@/api/highCollarSubsidy'
import { getContent, downloadStream } from '@/utils/index'
export default {
    // 老人补贴报表
    name: 'subElderlyReport',
    data() {
        return {
            areaProps: {
                lazy: true,
                checkStrictly: true,
                lazyLoad: this.lazyLoad
            },
            IDCATEGORY, // 身份类别
            SEXLIST, // 性别
            org: '',
            minDate: null,
            maxDate: null,
            orgIdoptions: [], // 所属区划
            month: '',
            searchParams: {
                tenantId: '',
                orgId: '',
                subsidyType: '',
                keyword: ''
            },
            queryParams: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            loading: false,
            tableData: [],
            monthpickoption: {
                disabledDate: time => {
                    if (this.minDate !== null && this.maxDate === null) {
                        let minMonth = this.minDate.getMonth() - 1,
                            lastYear = new Date(this.minDate).setMonth(minMonth - 11),
                            nextYear = new Date(this.minDate).setMonth(minMonth + 11)
                        let minTime = this.minDate.getTime()
                        let nextTime = new Date().setMonth(new Date().getMonth() + 11)
                        let lastTime = new Date().setMonth(new Date().getMonth() - 11)
                        if (minTime <= nextTime || minTime >= lastTime) {
                            //开始日期少于当前月+12个月 并且大于当前月-12个月，则表示只限制前面的范围
                            // time.valueOf() > new Date().getTime()
                            return time.valueOf() > new Date().setMonth(new Date().getMonth() - 1) || time.valueOf() > nextYear.valueOf() || time.valueOf() < lastYear.valueOf()
                        } else {
                            // 只能选 minDate 前后一年的范围
                            return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf()
                        }
                    } else {
                        let startMonth = this.month[0]
                        let month = new Date(startMonth).getMonth()
                        let lastMonth = new Date(startMonth).setMonth(month - 11)
                        //如果有默认值，只禁用开始日期，因为已经限定了禁用未来范围，且默认值已经为12个月范围
                        return this.monthPick(time) || time < lastMonth.valueOf()
                    }
                },
                onPick: ({ minDate, maxDate }) => {
                    this.minDate = minDate
                    this.maxDate = maxDate
                }
            }
        }
    },
    mounted() {
        this.initMonth()
        this.getList()
    },
    methods: {
        getContent,
        // 获取行政区划
        async lazyLoad(node, resolve) {
            const { data } = node
            let res
            if (data) {
                res = await administrativeDivision({ organizationId: data.id })
            } else {
                res = await administrativeDivision()
            }
            const nodes = res.data.map(item => ({
                value: item.organizationId,
                label: item.organizationName,
                id: item.organizationId
            }))
            resolve(nodes)
        },
        change(value, item) {
            console.log(value, item, 'change')
            if (value.length === 2) {
                this.searchParams.tenantId = value[value.length - 1]
            } else if (value.length === 3) {
                this.searchParams.tenantId = value[value.length - 2]
                this.searchParams.orgId = value[value.length - 1]
            } else {
                this.searchParams.tenantId = ''
                this.searchParams.orgId = ''
            }
        },
        // 选择月份
        onChangeDate(val) {
            console.log(val, 'onChangeDate')
            this.minDate = val ? `${val[0]}-01` : null
            this.maxDate = val ? `${val[1]}-28` : null
        },
        initMonth() {
            // 获取当前的月份信息
            const date = new Date() // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
            const year = date.getFullYear() // 获取当前年份，值是这样的： 2021
            let month = date.getMonth() + 1 // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
            const minMonth = `${year}-${month - 2 < 10 ? '0' + (month - 2) : month - 2}`
            const maxMonth = `${year}-${month - 1 < 10 ? '0' + (month - 1) : month - 1}`
            this.month = [minMonth, maxMonth]
            this.minDate = `${minMonth}-01`
            this.maxDate = `${maxMonth}-28`
        },
        monthPick(time) {
            // 获取当前的月份信息
            const date = new Date() // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
            const year = date.getFullYear() // 获取当前年份，值是这样的： 2021
            let month = date.getMonth() + 1 // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
            if (month >= 1 && month <= 9) {
                // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
                month = '0' + (month - 1)
            }
            const nowDate = year.toString() + month.toString() // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107

            // 获取时间选择器的月份信息
            const timeyear = time.getFullYear() // 获取时间选择器的年份（有很多）
            let timemonth = time.getMonth() + 1 // 与上面同理
            if (timemonth >= 1 && timemonth <= 9) {
                timemonth = '0' + timemonth
            }
            const elTimeData = timeyear.toString() + timemonth.toString()

            // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
            // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
            // 大于等于当前月份都不可选
            return elTimeData > nowDate // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
        },
        // 搜索
        doSearch() {
            this.queryParams.pageNum = 1
            this.getList()
        },
        // 重置
        doReset() {
            this.searchParams = {
                tenantId: '',
                orgId: '',
                subsidyType: '',
                keyword: ''
            }
            this.initMonth()
            this.doSearch()
        },
        getList() {
            this.loading = true
            reportList({ ...this.searchParams, startTime: this.minDate, endTime: this.maxDate })
                .then(res => {
                    this.tableData = res.data.rows
                    this.total = res.data.total
                    this.loading = false
                })
                .catch(() => {
                    this.tableData = []
                    this.total = 0
                    this.loading = false
                })
        },
        // 导出
        async doExport() {
            const res = await reportListExport({ ...this.searchParams, startTime: this.minDate, endTime: this.maxDate })
            downloadStream(res, '补贴老人报表.xlsx')
        }
    }
}
</script>

<style lang="scss" scoped>
.subElderlyReport {
    .searchBox {
        .w200px {
        }
        .search {
            margin-left: 20px;
        }
        .add {
            float: right;
        }
    }
}
</style>
